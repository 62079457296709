@import 'include-media/dist/_include-media.scss';

$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
  ) !default;

$primary: #4575ba;
$secondary: #bffd70;

$neutral0: #ffffff;
$neutral1: #f8f9fb;
$neutral2: #eef1f6;
$neutral3: #e4e8ee;
$neutral4: #dee3e8;
$neutral5: #a8b1bd;
$neutral6: #6a7381;
$neutral7: #49505a;
$neutral8: #353845;
$neutral9: #121417;

$success3: #09a57f;

$warning1: #fef7b9;
$warning3: #ffb400;
$warning5: #c33e01;

$error1: #fcd2cf;
$error5: #ae0a0a;

// Legacy
$gray: #bcc1c8;
$gray-text: #919497;
$gray-light: #e9ecef;
$gray-background: #f4f4f4;
$gray-line: #dee3e8;
$gray-dark: #49505a;
$green: #bffd70;
$green-icon: #09a57f;
$green-background: #ddfded;
$green-text: #027357;
$red: #d32f2f;
$sky-blue: #c3eaff;
$warning2: #ffda6c;
